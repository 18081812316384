<template>
  <v-container fluid>
    <h1>Hello World</h1>
  </v-container>
</template>

<script>
export default {
  components: {},
  methods: {
    confirmEmail() {
      this.$store.dispatch('misc/setLoading', {
        status: true,
        text: 'Confirming E-Mail. Please wait...',
      });
      let token = this.$route.params.token;
      this.$store
        .dispatch('confirmEmail', token)
        .then(response => {
          this.$store.dispatch('misc/setSnack', {
            text: response.data.msg,
            color: 'success',
          });
        })
        .catch(error => {
          console.log(error);
          this.$store.dispatch('misc/setSnack', {
            text: error.response.data.msg,
            color: 'error',
          });
        })
        .finally(() => {
          this.$store.dispatch('misc/setLoading', false);
          this.$router.push({ name: 'Login' });
        });
    },
  },
  created() {
    this.confirmEmail();
  },
};
</script>

<style></style>
